@font-face {
  font-family: 'Gibson';
  src: url('./assets/fonts/gibson-regular-webfont.woff2') format('woff2'),
    url('./assets/fonts/gibson-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Gibson';
  src: url('./assets/fonts/gibson-semibold-webfont.woff2') format('woff2'),
    url('./assets/fonts/gibson-semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Gibson';
  src: url('./assets/fonts/gibson-light-webfont.woff2') format('woff2'),
    url('./assets/fonts/gibson-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-gray-200;
}

table {
  @apply w-full border border-collapse;
}
table thead tr > th {
  @apply text-left px-6 py-2 font-normal;
}
table tbody tr > td {
  @apply text-left p-6 border-t border-b;
}
table tbody tr:nth-child(odd) > td {
  @apply bg-gray-100;
}
.centered-box {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.subscription-item input:checked + div + div {
  @apply bg-primary text-white;
}

.field-suggestion > strong {
  @apply absolute right-0 top-full w-[180px] bg-white font-normal   text-gray-400 px-3 py-2 hidden border border-gray-300 text-[11px] rounded;
}
.field-suggestion:hover > strong {
  @apply block;
}

.custom-radio input:checked + .toggle {
  @apply justify-end from-accent-150 to-accent-250;
}

.custom-radio-hl input + .toggle {
  @apply justify-end;
}
.custom-radio-hl input + .toggle > svg {
  @apply hidden;
}
.custom-radio-hl input:checked + .toggle {
  @apply border-accent-250;
}
.custom-radio-hl input:checked + .toggle > svg {
  @apply block;
}

.react-datepicker__week,
.react-datepicker__day-names {
  @apply flex text-center px-1 py-1;
}

.react-datepicker__week .react-datepicker__day,
.react-datepicker__day-name {
  @apply flex-1 text-center px-1 py-1;
}
.react-datepicker__day--disabled,
.react-datepicker__day--outside-month {
  @apply opacity-50;
}

.react-datepicker__day:hover {
  @apply rounded-full bg-gradient-to-b from-accent-500 to-accent-600 text-white cursor-pointer;
}

.react-datepicker__day--selected {
  @apply rounded-full bg-gradient-to-b from-accent-500 to-accent-600 text-white;
}

.timepicker .react-datepicker__input-container > input {
  @apply border w-full border-gray-200 px-3 py-1 rounded-md text-sm text-primary;
}

.timepicker .react-datepicker__tab-loop {
  @apply absolute left-2 top-full right-2 z-20;
}
.timepicker .react-datepicker__tab-loop .react-datepicker-popper {
  @apply left-0 right-0 top-0 pt-8 w-full bg-white border border-gray-200 h-52 overflow-y-scroll rounded-md;
}
.timepicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker-time__header {
  @apply h-8 leading-8 text-center absolute top-0 left-0 right-0 bg-gray-200;
}

.timepicker .react-datepicker__time-list-item {
  @apply px-3 py-2 text-center cursor-pointer;
}
.timepicker .react-datepicker__time-list-item:hover {
  @apply bg-gradient-to-b from-accent-500 to-accent-600 text-white left-44;
}

.swipeconfig .-left-32 {
  left: -123px;
}

.swipeconfig .-right-32 {
  right: -123px;
}
.swipeconfig .-left-44 {
  left: -200px;
}
.swipeconfig .-right-44 {
  right: -200px;
}
.swipeconfig .w-44 {
  width: 200px;
}
.swipeconfig .w-32 {
  width: 123px;
}

.rangepicker input {
  @apply text-center max-w-full  p-3 border border-gray-300 rounded-md;
}

.rangepicker .react-datepicker-popper {
  @apply left-0 border border-gray-300 bg-white rounded-md  shadow-md overflow-hidden z-[1000];
}
.rangepicker .react-datepicker__month-container {
  @apply p-2;
}

.rhap_controls-section {
  @apply flex justify-center;
}
.rhap_controls-section > * {
  @apply mx-1;
}

.rhap_progress-section {
  @apply relative pt-4 pb-4 w-[241px] mx-auto;
}
.rhap_progress-bar.rhap_progress-bar-show-download {
  @apply bg-seekbar bg-no-repeat bg-center h-[44px];
}
.rhap_progress-filled {
  @apply bg-white bg-seekbar-progress bg-no-repeat bg-center h-[44px];
}
.rhap_progress-section #rhap_current-time {
  @apply absolute left-0 bottom-0 text-xs text-gray-800;
}
.rhap_progress-section .rhap_total-time {
  @apply absolute right-0 bottom-0 text-xs text-gray-800;
}
