.grow-wrap,
.grow-wrap-2 {
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
  width: 100%;
}

.grow-wrap::after,
.grow-wrap-2::after {
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) ' ';

  /* This is how textarea text behaves */
  white-space: pre-wrap;

  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}
.grow-wrap > textarea,
.grow-wrap-2 > textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;
  outline: none;
  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;
}
.grow-wrap > textarea,
.grow-wrap::after {
  /* Identical styling required!! */
  width: 264px;
  border: 2px solid #005fcc;
  border-radius: 4px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 5px 30px 5px 35px;
  font: inherit;
  outline: none;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}
.grow-wrap-2 > textarea,
.grow-wrap-2::after {
  /* Identical styling required!! */
  width: 268px;
  border: 2px solid #005fcc;
  border-radius: 4px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 5px 30px 5px 35px;
  font: inherit;
  outline: none;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}

.blue-gradient {
  background: linear-gradient(45deg, #ff0000, #00ff00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
